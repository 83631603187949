
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&family=Nunito:wght@400;500&display=swap');

/* fontss -------------------------------------*/
@layer base {
    html {
       font-family:  'Montserrat', sans-serif;
    }
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

/* styles.css */
.no-number-arrows::-webkit-inner-spin-button,
.no-number-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}


.text-ellipsis {
    max-width: 100%; /* Ensure the element doesn't exceed its container */
    overflow: hidden; /* Hide overflowing content */
    text-overflow: ellipsis; /* Display ellipsis (...) for overflow */
    white-space: nowrap; /* Prevent text from wrapping */
}

/* Select the placeholder text within the input */
.custom-placeholder::placeholder {
    font-size: 12px; 
    color: #535963;
}

.custom-placeholder-amount::placeholder {
    font-size: 35px; 
    color: #535963;
    height: 52.5px;
}

.custom-holder::placeholder {
    font-size: 14px; 
    color: #535963;
}

  
/* showcase background */
.showcasebg{
    background-image: url('../images/Graphic-Blue-Wave.svg'), url('../images/Graphic-Purple-Wave.svg'), url('../images/Graphic-Teal-Wave.svg');
    background-position: bottom left, bottom left, bottom left; /* Adjust positions as needed */
    background-size: cover, cover, cover; 
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat; 
    /* background-attachment: fixed, fixed, fixed; */
}

/* Take full of your crypto grid --------------*/
.grid-container {
    display: grid;
    grid-template-columns: auto auto ;
    gap: 5rem;
}

@media (max-width:926px){
    .grid-container {
        display: grid;
        grid-template-columns: auto auto;
        gap: 2rem;
    }
}

@media (max-width:558px){
    .grid-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 6rem;
    }
}

.boxshadowAir{
    box-shadow: 10px 10px 17px -8px rgba(33,33,64,0.51);
    -webkit-box-shadow: 10px 10px 17px -8px rgba(33,33,64,0.51);
    -moz-box-shadow: 10px 10px 17px -8px rgba(33,33,64,0.51);
}
.boxshadowAi{
    box-shadow: 1px 0px 17px 7px rgba(41,41,94,0.9);
    -webkit-box-shadow: 1px 0px 17px 7px rgba(41,41,94,0.9);
    -moz-box-shadow: 1px 0px 17px 7px rgba(41,41,94,0.9);
}

.box-shadowTradeBtn{
    box-shadow: 0px 0px 5px 3px rgba(169,171,173,0.18);
    -webkit-box-shadow: 0px 0px 5px 3px rgba(169,171,173,0.18);
    -moz-box-shadow: 0px 0px 5px 3px rgba(169,171,173,0.18);
}

